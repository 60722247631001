
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        ValidationProvider,
    },
})
export default class PropertyPartInstallationHeating extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: ProperyInstallationHeating;

    localValue: ProperyInstallationHeating = {
        has_centralized_heating: null,
        has_decentralized_heating: null,
        has_water_heating: null,
        centralized_heating: [], 
        centralized_heating_fuel: [], 
        decentralized_heating: [], 
        water_heating: [],
        heating_type: null,
    };

    toggleArrayProperty(property: string[], value: string) {
        this.toggleArrayPropertyValue(property, value);

        this.handleInput();
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: ProperyInstallationHeating) {
        this.localValue = newValue;
    }
}
